import { ImageContent, RichText } from "@punks/core"
import SwiperCore from "swiper"
import Link from "next/link"
import React from "react"
import { SanityNextImg } from "../../../cms/components"
import { brandPagePath } from "../../../root/routing/urls"
import CustomButton from "../../atoms/Button"
import SwiperCarousel from "../../molecules/SwiperCarousel"
import { CustomContainer } from "../../spacings"
import { CtaData } from "../../types"
import classes from "./BrandsBar.module.css"
import classNames from "classnames"
import { TwRichTextField } from "../../../root/punks/core/fields/RichTextField"

export interface BrandsBannerBrand {
  name: string
  slug: string
  logo: ImageContent
  logoHeight?: string
  logoHeightMobile?: string
}

export interface BrandsBarItem {
  brand: BrandsBannerBrand
}

export interface BrandsBarProps {
  title: RichText
  items: BrandsBarItem[]
  cta?: CtaData
}

const BrandsBar = ({ items, title, cta }: BrandsBarProps) => {
  const [carousel, setCarousel] = React.useState<SwiperCore | undefined>()
  const [isEnd, setIsEnd] = React.useState(false)
  const [isStart, setIsStart] = React.useState(true)

  const slides = items
    .filter((x) => x.brand.logo)
    .map((x, index) => (
      <div key={index} className={`${classes.slide} noselect`}>
        <Link href={brandPagePath(x.brand.slug)} passHref prefetch={false}>
          <a>
            <SanityNextImg
              asset={x.brand.logo}
              layout="fill"
              alt={x.brand.name}
              blurUp
            />
          </a>
        </Link>
      </div>
    ))

  if (cta) {
    slides.push(
      <div
        key="cta"
        className={`${classes.slide} ${classes.ctaSlide} noselect`}
      >
        {cta && (
          <Link href={cta.url} passHref prefetch={false}>
            <a>
              <CustomButton
                variant="text"
                classes={{
                  button: classes.cta,
                }}
              >
                {cta.label}
              </CustomButton>
            </a>
          </Link>
        )}
      </div>
    )
  }

  React.useEffect(() => {
    if (carousel && !carousel.destroyed) {
      setIsEnd(carousel.isEnd)
      carousel.on("slideChange", (x) => {
        setIsEnd(x.isEnd)
        setIsStart(x.isBeginning)
      })
    }
  }, [carousel])

  return (
    <CustomContainer
      mobileNoGutters
      className={`mt-6 mb-2 px-2 carouselMd:px-0 relative ${classes.root}`}
    >
      <div className={classes.boxSx}>
        <div
          className={`${classes.arrow} noselect ${
            isStart ? classes.arrowStart : ""
          }`}
          onClick={() => carousel?.slidePrev()}
        >
          ←
        </div>
      </div>
      <div className={classes.line}>
        <div className={classes.lineTitle}>
          <TwRichTextField value={title} component="span" variant="body2" />
        </div>
      </div>
      <SwiperCarousel
        className=""
        slidesToShowXs={3}
        slidesToShowSm={4}
        slidesToShowMd={5}
        slidesPerGroupMd={4}
        slidesPerGroupSm={2}
        slidesPerGroupXs={2}
        loop={false}
        dots={false}
        h100
        onSwiper={setCarousel}
        centerPadding={26}
        centerPaddingSm={40}
        centerPaddingMd={60}
      >
        {slides}
      </SwiperCarousel>
      <div className={classes.line}></div>
      <div className={classes.boxDx}>
        <div
          className={`${classes.arrow} noselect ${
            isEnd ? classes.arrowEnd : ""
          }`}
          onClick={() => carousel?.slideNext()}
        >
          →
        </div>
      </div>
      <div
        className={classNames(classes.shadow, {
          [classes.shadowEnd]: isEnd,
        })}
      ></div>
      <div
        className={classNames(classes.shadow, classes.shadowText, {
          [classes.shadowEnd]: isEnd,
        })}
      ></div>
    </CustomContainer>
  )
}

export default BrandsBar
