import React from "react"
import BrandsBar from "../../../../../ui/organisms/BrandsBar"
import { toBrandsBarProps } from "../../../converters/brandsBar"

interface Props {
  content: any
}

const BrandsBarWrapper = ({ content }: Props) => {
  return <BrandsBar {...toBrandsBarProps(content.item.brandsBar)} />
}

export default BrandsBarWrapper
