import { BrandsBarItem, BrandsBarProps } from "../../../ui/organisms/BrandsBar"
import { toCtaData } from "./cta"

const toBrandBarItem = (item: any): BrandsBarItem => ({
  brand: {
    slug: item.brand?.data?.slug?.current,
    name: item.brand?.data?.name,
    logo: item.brand?.data?.logo,
    logoHeight: item.logoHeight,
    logoHeightMobile: item.logoHeightMobile,
  },
})

export const toBrandsBarProps = (item: any): BrandsBarProps => {
  return {
    cta: item.data?.contents?.cta
      ? toCtaData(item.data.contents.cta)
      : undefined,
    title: item.data?.contents?.title,
    items: item.data?.contents?.items?.map((x: any) => toBrandBarItem(x)) ?? [],
  }
}
